import { shippingFormDataIsValid } from '~/graphql/utils/validation'
import type {
  CreateAddressInput,
  CreateCustomerInput,
  OrderDetailFragment,
  OrderDetailLineItemFragment, PaymentInput,
  ProductVariantFragment,
  ShippingMethodFragment,
} from '#graphql-operations'
import type { GetMutationArgs, GetMutationResult, GraphQLMiddlewareMutationName } from '#graphql-composable'
import type { GraphQLMiddlewareMutation } from '#build/nuxt-graphql-middleware'

interface CustomOrderStates {}
export type OrderState = 'Created' | 'Draft' | 'AddingItems' | 'ArrangingPayment' | 'PaymentAuthorized' | 'PaymentSettled' | 'PartiallyShipped' | 'Shipped' | 'PartiallyDelivered' | 'Delivered' | 'Modifying' | 'ArrangingAdditionalPayment' | 'Cancelled' | keyof CustomOrderStates

export function fetchActiveOrder() {
  const state = useStore()

  return reactiveLoad<OrderDetailFragment | undefined | null>(
    () => state.value.activeOrder,
    (activeOrder) => {
      state.value.activeOrder = activeOrder
    },
    () => useGraphqlQuery('activeOrder').then(result => result.data.activeOrder),
  )
}

export async function refreshActiveOrder() {
  const state = useStore()
  return useGraphqlQuery('activeOrder').then((result) => {
    state.value.activeOrder = result.data.activeOrder
    return result.data.activeOrder
  })
}

export function useActiveOrder() {
  const logger = usePino().child({ module: 'useActiveOrder' })
  const { ecommerce } = useAnalytics()
  const state = useStore()

  interface GraphQLMutationHandlerOptions<T> {
    logger: ReturnType<typeof usePino>
    onSuccess: (result: T) => void | Promise<void>
    onError: (error: Error) => void | Promise<void>
  }

  function createGraphQLMutationHandler<T extends GraphQLMiddlewareMutationName>(
    mutationName: T,
    options: GraphQLMutationHandlerOptions<GetMutationResult<T, GraphQLMiddlewareMutation>>,
  ) {
    return async (variables: GetMutationArgs<T, GraphQLMiddlewareMutation>[1]) => {
      const { log = logger, onSuccess, onError } = options
      const { [mutationName]: result } = (await useGraphqlMutation(mutationName, variables)).data

      if (isGqlType(result, 'Order')) {
        log.info(result, `${mutationName}`)
        state.value.activeOrder = result
        await onSuccess(result)
      } else {
        log.error(result, `${mutationName}`)
        onError(result)
      }
    }
  }

  const transitionOrderToState = async (nextState: OrderState) => {
    if (state.value.activeOrder?.state === nextState || !state.value.activeOrder?.id)
      return

    const { data } = await useGraphqlQuery('nextOrderStates')

    if (data.nextOrderStates.includes(nextState)) {
      const result = (await useGraphqlMutation('transitionOrderToState', { state: nextState })).data
      if (isGqlType(result.transitionOrderToState, 'Order')) {
        logger.info(result, 'transitionOrderToState')
        state.value.activeOrder = result.transitionOrderToState
      } else {
        logger.error(result, 'transitionOrderToState')
        state.value.activeOrderError = result.transitionOrderToState
      }
    }
  }

  const addItemToOrder = async (productVariant?: ProductVariantFragment, quantity = 1) => {
    await transitionOrderToState('AddingItems')

    if (productVariant && quantity) {
      const result = (await useGraphqlMutation('addItemToOrder', { productVariantId: productVariant.id, quantity })).data

      if (isGqlType(result.addItemToOrder, 'Order')) {
        state.value.activeOrder = result.addItemToOrder
      } else {
        logger.error(result, 'addItemToOrder')
        state.value.activeOrderError = result.addItemToOrder
      }
    }
  }

  const removeOrderLine = async (orderLine?: OrderDetailLineItemFragment) => {
    if (!orderLine?.id)
      return

    await transitionOrderToState('AddingItems')
    const result = (await useGraphqlMutation('removeOrderLine', { orderLineId: orderLine.id })).data

    if (isGqlType(result.removeOrderLine, 'Order')) {
      ecommerce.removeFromCart(orderLine)
      state.value.activeOrder = result.removeOrderLine
    } else {
      logger.error(result, 'removeOrderLine')
      state.value.activeOrderError = result.removeOrderLine
    }
  }

  const adjustOrderLine = async (orderLine?: OrderDetailLineItemFragment, quantity?: number) => {
    if (orderLine?.id && quantity != null) {
      await transitionOrderToState('AddingItems')
      const result = (await useGraphqlMutation('adjustOrderLine', { orderLineId: orderLine.id, quantity })).data

      if (isGqlType(result.adjustOrderLine, 'Order')) {
        logger.info(result, 'adjustOrderLine')
        state.value.activeOrder = result.adjustOrderLine
      } else {
        logger.error(result, 'adjustOrderLine')
        state.value.activeOrderError = result.adjustOrderLine
      }
    }
  }

  const setOrderShippingMethod = async (shippingMethod?: Pick<ShippingMethodFragment, 'id' | 'code' | 'name' | 'description'>) => {
    if (shippingMethod) {
      await transitionOrderToState('AddingItems')
      const result = (await useGraphqlMutation('setOrderShippingMethod', { shippingMethodId: shippingMethod.id })).data

      if (isGqlType(result.setOrderShippingMethod, 'Order')) {
        ecommerce.addShippingInfo(shippingMethod)
        logger.info(result, 'setOrderShippingMethod')
        state.value.activeOrder = result.setOrderShippingMethod
      } else {
        logger.error(result, 'setOrderShippingMethod')
        state.value.activeOrderError = result.setOrderShippingMethod
      }
    }
  }

  const setCustomerForOrder = async (input: CreateCustomerInput) => {
    await transitionOrderToState('AddingItems')

    const result = (await useGraphqlMutation('setCustomerForOrder', { input })).data

    if (isGqlType(result.setCustomerForOrder, 'Order')) {
      state.value.activeOrder = {
        ...state.value.activeOrder,
        customer: result.setCustomerForOrder.customer,
      }
      logger.info(result, 'setCustomerForOrder')
    } else {
      logger.error(result, 'setCustomerForOrder')
      state.value.activeOrderError = result.setCustomerForOrder
    }
  }

  const setOrderShippingAddress = async (input: Partial<CreateAddressInput>, shouldValidate = true) => {
    const isComplete = shouldValidate ? shippingFormDataIsValid(input) : true

    if (isComplete) {
      await transitionOrderToState('AddingItems')

      const result = (await useGraphqlMutation('setOrderShippingAddress', { input: assignBlankAddressFields(input) })).data

      if (isGqlType(result.setOrderShippingAddress, 'Order')) {
        logger.info(result, 'setOrderShippingAddress')
        state.value.activeOrder = {
          ...state.value.activeOrder,
          shippingAddress: result.setOrderShippingAddress.shippingAddress,
        }
      } else {
        logger.error(result, 'setOrderShippingAddress')
        state.value.activeOrderError = result.setOrderShippingAddress
      }
    }
  }

  const addPaymentToOrder = async (input: PaymentInput) => {
    await transitionOrderToState('ArrangingPayment')
    const result = (await useGraphqlMutation('addPaymentToOrder', { input })).data

    if (isGqlType(result.addPaymentToOrder, 'Order')) {
      logger.info(result, 'addPaymentToOrder')
      state.value.activeOrder = {
        ...state.value.activeOrder,
        payments: result.addPaymentToOrder.payments,
      }
    } else {
      logger.error(result, 'addPaymentToOrder')
      state.value.activeOrderError = result.addPaymentToOrder
    }
  }

  return {
    activeOrder: computed(() => state.value.activeOrder),
    error: computed(() => state.value.activeOrderError),
    addItemToOrder,
    removeOrderLine,
    adjustOrderLine,
    setOrderShippingMethod,
    setCustomerForOrder,
    setOrderShippingAddress,
    transitionOrderToState,
    addPaymentToOrder,
  }
}

const addressFields: Array<keyof CreateAddressInput> = ['city', 'company', 'countryCode', 'fullName', 'phoneNumber', 'province', 'streetLine1', 'streetLine2']
const addressFieldsLength = addressFields.length
function assignBlankAddressFields(input: Partial<CreateAddressInput>) {
  for (let i = 0; i < addressFieldsLength; i++) {
    const field = addressFields[i]
    if (!(field in input) || input[field] == null)
      input[field] = ''
  }

  return input as CreateAddressInput
}
