import { defineNuxtPlugin } from '#imports'
import { createImageHandler } from '#image-handler'
// @ts-expect-error build
import { imageOptions } from '#build/image-options'

export default defineNuxtPlugin(() => {
  const img = createImageHandler(imageOptions)

  return {
    provide: {
      img,
    },
  }
})
