import { datadogRum } from '@datadog/browser-rum'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'

export default defineNuxtPlugin((_nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const initRumConfig = runtimeConfig.public.datadog.rum

  datadogRum.init(initRumConfig)

  datadogRum.startSessionReplayRecording()

  return {
    provide: {
      datadogRum,
    },
  }
})
