export default defineNuxtPlugin(async (nuxtApp) => {
  // Skip plugin when rendering error page
  if (nuxtApp.payload.error)
    return {}

  // No ssr
  if (process.server)
    return

  const { $localePath } = useNuxtApp()
  const { data: activeCustomer, refresh: updateSession } = await useAsyncData('activeCustomer', () => useGraphqlQuery('activeCustomer').then(({ data }) => data.activeCustomer))

  const loggedIn = computed(() => !!activeCustomer.value)

  addRouteMiddleware('auth', (to, from) => {
    if (to.meta.auth && !loggedIn.value) {
      if (process.server && to.path === from.path)
        return
      return `${$localePath('/auth/login')}?redirectTo=${to.path}`
    }
  },
  { global: true })

  const currentRoute = useRoute()

  if (process.client) {
    watch(loggedIn, async (loggedIn) => {
      if (currentRoute.meta.auth && !loggedIn)
        await navigateTo(`${$localePath('/auth/login')}?redirectTo=${currentRoute.path}`)
    })
  }

  if (loggedIn.value && ['/auth/login', $localePath('/auth/login')].includes(currentRoute.path)) {
    const to = (currentRoute.query.redirectTo || $localePath('/')).toString()
    await navigateTo(to)
  }

  return {
    provide: {
      auth: { loggedIn, session: activeCustomer, activeCustomer, updateSession },
    },
  }
})
