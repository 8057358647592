import type { ImageContext, ImageDimensionsOptions, ImageSrc } from '../../types'
import { normalizeSize } from '../utils'

interface DimensionsVariant {
  width: number
  height: number
  dpi: number
  src: string
}

export const getDimensions = (
  ctx: ImageContext,
  input: string | ImageSrc,
  options: ImageDimensionsOptions = {},
) => {
  const width = normalizeSize(options.edits?.resize?.width) ?? 0
  const height = normalizeSize(options.edits?.resize?.height) ?? 0

  const variants: DimensionsVariant[] = []
  const dpi = [...(ctx.options.dpi ?? [])]

  if (typeof options.dpi === 'string') {
    for (const size of options.dpi.split(/[\s,]+/).filter(Boolean)) {
      dpi.push(size)
    }
  } else {
    dpi.push(...(options.dpi ?? '1x'))
  }

  // parse dpi and remove duplicates
  const dpiSet = Array.from(new Set(dpi.map(normalizeSize))).filter(Boolean) as number[]

  for (const dpi of dpiSet) {
    const currentWidth = Math.round(width * dpi)
    const currentHeight = Math.round(height * dpi)

    variants.push({
      width: currentWidth,
      height: currentHeight,
      dpi,
      src: ctx.$img!(input, {
        ...options.edits,
        resize: {
          ...options.edits?.resize,
          width: currentWidth,
          height: currentHeight,
        },
      }, options),
    })
  }

  variants.sort((left, right) => left.dpi - right.dpi)

  const defaultVariant = variants.find(variant => variant.dpi === 1)

  return {
    srcset: variants.map(variant => `${variant.src} ${variant.dpi}x`).join(', '),
    src: defaultVariant?.src,
  }
}
