import type { LoggerOptions } from 'pino'
import createLogger from 'pino'

const loggerOptions: LoggerOptions = {}

const pinoPretty = {
  target: 'pino-pretty',
  options: {
    colorize: true,
    translateTime: 'SYS:HH:MM:ss.l o',
    ignore: 'pid,hostname',
    levelFirst: true,
  },
}

export default defineNuxtPlugin(async () => {
  const pinoConfig = useRuntimeConfig().public.pino
  Object.assign(loggerOptions, pinoConfig)

  if (process.server) {
    loggerOptions.name = 'nuxt.server'

    if (pinoConfig?.pretty) {
      loggerOptions.transport = pinoPretty
    }
  }

  if (process.client) {
    loggerOptions.name = 'nuxt.client'
  }

  const pino = createLogger(loggerOptions)

  return {
    provide: {
      pino,
    },
  }
})
