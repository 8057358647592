import { hasProtocol, joinURL, withLeadingSlash, withoutTrailingSlash } from 'ufo'
import defu from 'defu'
import type { ImageContext, ImageOptions, ImageSrc } from '../../types'
import { isHttpImage, isS3Image, normalizeSize } from '../utils'
import { getPreset } from './getPreset'
import { getImage } from './getImage'

export const resolveImage = (
  ctx: ImageContext,
  input: string | ImageSrc = '',
  options?: ImageOptions,
) => {
  if (input === '' || Object.keys(input).length === 0) {
    // return undefined
    throw new TypeError(`Image source is empty. Please provide a valid image source. (received ${typeof input}: ${JSON.stringify(input)})`)
  }

  if (isHttpImage(input)) {
    // normalize host and path slashes
    input = input.url ? input.url : `${withoutTrailingSlash(input.host)}${withLeadingSlash(input.path)}`
  }

  if (isS3Image(input)) {
    throw new Error('S3 images are not supported yet')
  }

  if (input.indexOf('data:') === 0) {
    return { url: input }
  }

  const defaults = ctx.options.defaults || {}
  const preset = getPreset(ctx, options?.preset)

  // normalize input
  input = hasProtocol(input) ? input : withLeadingSlash(input)

  // resolve alias
  if (ctx.options.alias) {
    for (const base in ctx.options.alias) {
      if (input.indexOf(base) === 0) {
        input = joinURL(ctx.options.alias[base], input.slice(base.length))
      }
    }
  }

  // externalize remote images if domain does not match with `domains`
  if (hasProtocol(input) && ctx.options.domains && !ctx.options.domains.includes('*')) {
    const { host } = new URL(input)
    // domains are normalized to hostname in module
    if (!ctx.options.domains.includes(host)) {
      return { url: input }
    }
  }

  const opts: ImageOptions = defu(options, preset, defaults)

  const expectedFormat = opts.edits?.toFormat

  // normalize sizes
  if (opts.edits?.resize?.width) {
    opts.edits.resize.width = normalizeSize(opts.edits.resize.width)
  }

  if (opts.edits?.resize?.height) {
    opts.edits.resize.height = normalizeSize(opts.edits.resize.height)
  }

  // normalize quality
  if (opts.quality) {
    opts.quality = normalizeSize(opts.quality)
  }

  // normalize effort
  if (opts.effort) {
    opts.effort = normalizeSize(opts.effort)
  }

  const image = getImage(input, opts, ctx)

  image.format = image.format || expectedFormat || ''

  return image
}
