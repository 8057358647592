import type { ImageContext, ImageOptions, ImageRequest, ResolvedImage } from '../../types'
import { stringToBase64Url } from '../utils'

// export const validateDomains = true
// export const supportsAlias = true

export function getImage(src: string, options: ImageOptions, _context: ImageContext) {
  const { quality, edits, effort } = options

  if (['127.0.0.1', 'localhost'].includes(new URL(src).hostname))
    return { url: src } as ResolvedImage

  const request: ImageRequest = {
    url: src,
    edits: { ...edits },
    quality,
    effort,
  }

  const encoded = stringToBase64Url(JSON.stringify(request))

  // const url = process.env.NODE_ENV === 'production' ? `/_image/${encoded}` : `https://scontent.uzo.io/v1/images/${encoded}`
  const url = `/api/images/${encoded}`

  // const newUrl = new URL(request.url!)
  // return {
  //   url: `http://${newUrl.host}${newUrl.pathname}${newUrl.search}`,
  // }

  return { url } as ResolvedImage
}
