import revive_payload_client_QlXPWeuU6A from "/usr/local/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.52.0_rollup@3.29.4_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sVguN59EFc from "/usr/local/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.52.0_rollup@3.29.4_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_iBLSS90ahk from "/usr/local/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.52.0_rollup@3.29.4_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Lb2GE4J9vU from "/usr/local/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.52.0_rollup@3.29.4_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/local/app/.nuxt/components.plugin.mjs";
import prefetch_client_97PIJWXXG8 from "/usr/local/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.52.0_rollup@3.29.4_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import provideState_hE6SDQN8zW from "/usr/local/app/node_modules/.pnpm/nuxt-graphql-middleware@4.0.0-beta.9_graphql-tag@2.12.6_graphql@16.8.1_nuxt@3.8.0_rollup@3.29_tjsy6iugxa4mcgis56sj3uz43a/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.mjs";
import plugin_aEL9vYN3fL from "/usr/local/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@3.29.4/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_client_aMSXXTX8Pv from "/usr/local/app/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2_rollup@3.29.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import unocss_MzCDxu9LMj from "/usr/local/app/.nuxt/unocss.mjs";
import composition_Z9xIlOqS7I from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.11_rollup@3.29.4_vue-router@4.2.5_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_IuErCHBOwu from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.11_rollup@3.29.4_vue-router@4.2.5_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import nuxt_plugin_da0l7bk1eK from "/usr/local/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.3_rollup@3.29.4/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import pwa_client_MPFFBK9fg2 from "/usr/local/app/node_modules/.pnpm/@vite-pwa+nuxt@0.3.5_rollup@3.29.4_vite@4.5.0_workbox-build@7.0.0_workbox-window@7.0.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_Pb4VNP6OMp from "/usr/local/app/modules/stripe/runtime/plugin.ts";
import plugin_HMUycbzStU from "/usr/local/app/modules/pino/runtime/plugin.ts";
import plugin_4fNvsrmUFT from "/usr/local/app/modules/image-handler/runtime/plugin.ts";
import plugin_xDtleEcACi from "/usr/local/app/modules/gtm/runtime/plugin.ts";
import plugin_client_n65crEo4Aa from "/usr/local/app/modules/datadog/runtime/plugin.client.ts";
import chunk_reload_client_tsKIKzGsm2 from "/usr/local/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.52.0_rollup@3.29.4_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_JVRTMJLzZo from "/usr/local/app/node_modules/.pnpm/nuxt@3.8.0_eslint@8.52.0_rollup@3.29.4_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import _0_graphqlConfig_yaUZblG674 from "/usr/local/app/plugins/0.graphqlConfig.ts";
import _1_auth_slaK5pfDbE from "/usr/local/app/plugins/1.auth.ts";
import foxentry_client_gcy3vwc779 from "/usr/local/app/plugins/foxentry.client.ts";
import scroll_client_HJUo2UKx9n from "/usr/local/app/plugins/scroll.client.ts";
import sentry_client_shVUlIjFLk from "/usr/local/app/plugins/sentry.client.ts";
import vee_validate_KcKlKmvCrJ from "/usr/local/app/plugins/vee-validate.ts";
export default [
  revive_payload_client_QlXPWeuU6A,
  unhead_sVguN59EFc,
  router_iBLSS90ahk,
  payload_client_Lb2GE4J9vU,
  components_plugin_KR1HBZs4kY,
  prefetch_client_97PIJWXXG8,
  provideState_hE6SDQN8zW,
  plugin_aEL9vYN3fL,
  plugin_client_aMSXXTX8Pv,
  unocss_MzCDxu9LMj,
  composition_Z9xIlOqS7I,
  i18n_IuErCHBOwu,
  nuxt_plugin_da0l7bk1eK,
  pwa_client_MPFFBK9fg2,
  plugin_Pb4VNP6OMp,
  plugin_HMUycbzStU,
  plugin_4fNvsrmUFT,
  plugin_xDtleEcACi,
  plugin_client_n65crEo4Aa,
  chunk_reload_client_tsKIKzGsm2,
  check_outdated_build_client_JVRTMJLzZo,
  _0_graphqlConfig_yaUZblG674,
  _1_auth_slaK5pfDbE,
  foxentry_client_gcy3vwc779,
  scroll_client_HJUo2UKx9n,
  sentry_client_shVUlIjFLk,
  vee_validate_KcKlKmvCrJ
]