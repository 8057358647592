import type { ImageContext } from '../../types'

export const getPreset = (ctx: ImageContext, name?: string) => {
  if (!name) {
    return {}
  }

  if (!ctx.options.presets[name]) {
    throw new Error(`Image preset "${name}" not found.`)
  }

  return ctx.options.presets[name]
}
