import type { ChannelFragment } from '#graphql-operations'

export const fetchActiveChannel = () => {
  const state = useStore()

  return reactiveLoad<ChannelFragment | null | undefined>(
    () => state.value.activeChannel,
    (activeChannel) => {
      state.value.activeChannel = activeChannel
    },
    () => useGraphqlQuery('activeChannel').then(result => result.data.activeChannel),
  )
}

export const useActiveChannel = () => {
  const state = useStore()

  return {
    activeChannel: computed(() => state.value.activeChannel),
  }
}
