import type { ImageContext, ImageScreenSize, ImageSizesOptions, ImageSrc } from '../../types'
import { normalizeSize } from '../utils'

interface SizeVariant {
  width: number
  size: string | number
  screenMaxWidth: number
  media: string
  src: string
}

export const getSizes = (ctx: ImageContext, input: string | ImageSrc, options: ImageSizesOptions) => {
  const width = normalizeSize(options.edits?.resize?.width)
  const height = normalizeSize(options.edits?.resize?.height)
  const ratio = (width && height) ? width / height : 0
  const variants: SizeVariant[] = []

  const sizes: Record<string, string> = {}

  if (typeof options.sizes === 'string') {
    for (const size of options.sizes.split(/[\s,]+/).filter(Boolean)) {
      const [screen, breakpoint] = size.split(':')

      if (!screen || !breakpoint) {
        continue
      }

      sizes[screen.trim()] = breakpoint.trim()
    }
  } else {
    Object.assign(sizes, options.sizes)
  }

  for (const screen in sizes) {
    const screenMaxWidth = (ctx.options.screens && ctx.options.screens[screen as ImageScreenSize]) || Number(screen)

    let size = String(sizes[screen])

    const isFluid = `${size[size.length - 2]}${size[size.length - 1]}` === 'vw'

    if (!isFluid && /^\d+$/.test(size)) {
      size = `${size}px`
    }

    if (!isFluid && `${size[size.length - 2]}${size[size.length - 1]}` !== 'px') {
      continue
    }

    let currentWidth = Number.parseInt(size)

    if (!screenMaxWidth || !currentWidth) {
      continue
    }

    if (isFluid) {
      currentWidth = Math.round((currentWidth / 100) * screenMaxWidth)
    }

    const currentHeight = ratio ? Math.round(currentWidth / ratio) : height

    variants.push({
      width: currentWidth,
      size,
      screenMaxWidth,
      media: `(max-width: ${screenMaxWidth}px)`,
      src: ctx.$img!(input, {
        ...options.edits,
        resize: {
          ...options.edits?.resize,
          width: currentWidth,
          height: currentHeight,
        },
      }, options),
    })
  }

  variants.sort((left, right) => left.screenMaxWidth - right.screenMaxWidth)

  const defaultVariant = variants[variants.length - 1]

  if (defaultVariant) {
    defaultVariant.media = ''
  }

  return {
    sizes: variants.map(variant => `${variant.media ? `${variant.media} ` : ''}${variant.size}`).join(', '),
    srcset: variants.map(variant => `${variant.src} ${variant.width}w`).join(', '),
    src: defaultVariant?.src,
  }
}
