import { default as _91_46_46_46slug_93ehlUViFFkaMeta } from "/usr/local/app/pages/[...slug].vue?macro=true";
import { default as indexbcW1kPi65kMeta } from "/usr/local/app/pages/account/addresses/index.vue?macro=true";
import { default as indexf0kufjSeQTMeta } from "/usr/local/app/pages/account/index.vue?macro=true";
import { default as _91code_93Z5gRwhAl1xMeta } from "/usr/local/app/pages/account/orders/[code].vue?macro=true";
import { default as indexyavSc54tHJMeta } from "/usr/local/app/pages/account/orders/index.vue?macro=true";
import { default as securityGIscjYEtcLMeta } from "/usr/local/app/pages/account/security.vue?macro=true";
import { default as accountkzLoSvJwtmMeta } from "/usr/local/app/pages/account.vue?macro=true";
import { default as approvalRreQXiJSeHMeta } from "/usr/local/app/pages/approval.vue?macro=true";
import { default as change_45emailUR6mw1en5xMeta } from "/usr/local/app/pages/auth/change-email.vue?macro=true";
import { default as loginF3gIq4xjsbMeta } from "/usr/local/app/pages/auth/login.vue?macro=true";
import { default as logoutgfTlJJpOwXMeta } from "/usr/local/app/pages/auth/logout.vue?macro=true";
import { default as new_45passwordHKAqxDljR1Meta } from "/usr/local/app/pages/auth/new-password.vue?macro=true";
import { default as registerD1YI4k4ySdMeta } from "/usr/local/app/pages/auth/register.vue?macro=true";
import { default as reset_45passwordjZT5RCzmJ4Meta } from "/usr/local/app/pages/auth/reset-password.vue?macro=true";
import { default as verify_45emailShAT883FSKMeta } from "/usr/local/app/pages/auth/verify-email.vue?macro=true";
import { default as _91code_93n8bQXXLUreMeta } from "/usr/local/app/pages/checkout/confirmation/[code].vue?macro=true";
import { default as indexS4P2YxqBHcMeta } from "/usr/local/app/pages/checkout/index.vue?macro=true";
import { default as checkoutU7ydjr5EV5Meta } from "/usr/local/app/pages/checkout.vue?macro=true";
import { default as _91slug_939CT60eHGEsMeta } from "/usr/local/app/pages/collections/[id]/[slug].vue?macro=true";
import { default as commitmentsPspvuWFo6MMeta } from "/usr/local/app/pages/commitments.vue?macro=true";
import { default as contactU3U0nxWLZbMeta } from "/usr/local/app/pages/contact.vue?macro=true";
import { default as indexZRmv4rwJvqMeta } from "/usr/local/app/pages/index.vue?macro=true";
import { default as _91slug_93Qd7SH03cQCMeta } from "/usr/local/app/pages/pages/[slug].vue?macro=true";
import { default as _91_91slug_93_939lFgEk0npoMeta } from "/usr/local/app/pages/products/[id]/[[slug]].vue?macro=true";
import { default as searchYhxkQS4hULMeta } from "/usr/local/app/pages/search.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93ehlUViFFkaMeta?.name ?? "slug___hu",
    path: _91_46_46_46slug_93ehlUViFFkaMeta?.path ?? "/hu/:slug(.*)*",
    meta: _91_46_46_46slug_93ehlUViFFkaMeta || {},
    alias: _91_46_46_46slug_93ehlUViFFkaMeta?.alias || [],
    redirect: _91_46_46_46slug_93ehlUViFFkaMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    path: accountkzLoSvJwtmMeta?.path ?? "/hu/account",
    children: [
  {
    name: indexbcW1kPi65kMeta?.name ?? "account-addresses___hu",
    path: indexbcW1kPi65kMeta?.path ?? "addresses",
    meta: indexbcW1kPi65kMeta || {},
    alias: indexbcW1kPi65kMeta?.alias || [],
    redirect: indexbcW1kPi65kMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: indexf0kufjSeQTMeta?.name ?? "account___hu",
    path: indexf0kufjSeQTMeta?.path ?? "",
    meta: indexf0kufjSeQTMeta || {},
    alias: indexf0kufjSeQTMeta?.alias || [],
    redirect: indexf0kufjSeQTMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91code_93Z5gRwhAl1xMeta?.name ?? "account-orders-code___hu",
    path: _91code_93Z5gRwhAl1xMeta?.path ?? "orders/:code()",
    meta: _91code_93Z5gRwhAl1xMeta || {},
    alias: _91code_93Z5gRwhAl1xMeta?.alias || [],
    redirect: _91code_93Z5gRwhAl1xMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/account/orders/[code].vue").then(m => m.default || m)
  },
  {
    name: indexyavSc54tHJMeta?.name ?? "account-orders___hu",
    path: indexyavSc54tHJMeta?.path ?? "orders",
    meta: indexyavSc54tHJMeta || {},
    alias: indexyavSc54tHJMeta?.alias || [],
    redirect: indexyavSc54tHJMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: securityGIscjYEtcLMeta?.name ?? "account-security___hu",
    path: securityGIscjYEtcLMeta?.path ?? "security",
    meta: securityGIscjYEtcLMeta || {},
    alias: securityGIscjYEtcLMeta?.alias || [],
    redirect: securityGIscjYEtcLMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/account/security.vue").then(m => m.default || m)
  }
],
    name: accountkzLoSvJwtmMeta?.name ?? undefined,
    meta: accountkzLoSvJwtmMeta || {},
    alias: accountkzLoSvJwtmMeta?.alias || [],
    redirect: accountkzLoSvJwtmMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/account.vue").then(m => m.default || m)
  },
  {
    name: approvalRreQXiJSeHMeta?.name ?? "approval___hu",
    path: approvalRreQXiJSeHMeta?.path ?? "/hu/approval",
    meta: approvalRreQXiJSeHMeta || {},
    alias: approvalRreQXiJSeHMeta?.alias || [],
    redirect: approvalRreQXiJSeHMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/approval.vue").then(m => m.default || m)
  },
  {
    name: change_45emailUR6mw1en5xMeta?.name ?? "auth-change-email___hu",
    path: change_45emailUR6mw1en5xMeta?.path ?? "/hu/auth/change-email",
    meta: change_45emailUR6mw1en5xMeta || {},
    alias: change_45emailUR6mw1en5xMeta?.alias || [],
    redirect: change_45emailUR6mw1en5xMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/auth/change-email.vue").then(m => m.default || m)
  },
  {
    name: loginF3gIq4xjsbMeta?.name ?? "auth-login___hu",
    path: loginF3gIq4xjsbMeta?.path ?? "/hu/auth/login",
    meta: loginF3gIq4xjsbMeta || {},
    alias: loginF3gIq4xjsbMeta?.alias || [],
    redirect: loginF3gIq4xjsbMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: logoutgfTlJJpOwXMeta?.name ?? "auth-logout___hu",
    path: logoutgfTlJJpOwXMeta?.path ?? "/hu/auth/logout",
    meta: logoutgfTlJJpOwXMeta || {},
    alias: logoutgfTlJJpOwXMeta?.alias || [],
    redirect: logoutgfTlJJpOwXMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: new_45passwordHKAqxDljR1Meta?.name ?? "auth-new-password___hu",
    path: new_45passwordHKAqxDljR1Meta?.path ?? "/hu/auth/new-password",
    meta: new_45passwordHKAqxDljR1Meta || {},
    alias: new_45passwordHKAqxDljR1Meta?.alias || [],
    redirect: new_45passwordHKAqxDljR1Meta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/auth/new-password.vue").then(m => m.default || m)
  },
  {
    name: registerD1YI4k4ySdMeta?.name ?? "auth-register___hu",
    path: registerD1YI4k4ySdMeta?.path ?? "/hu/auth/register",
    meta: registerD1YI4k4ySdMeta || {},
    alias: registerD1YI4k4ySdMeta?.alias || [],
    redirect: registerD1YI4k4ySdMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordjZT5RCzmJ4Meta?.name ?? "auth-reset-password___hu",
    path: reset_45passwordjZT5RCzmJ4Meta?.path ?? "/hu/auth/reset-password",
    meta: reset_45passwordjZT5RCzmJ4Meta || {},
    alias: reset_45passwordjZT5RCzmJ4Meta?.alias || [],
    redirect: reset_45passwordjZT5RCzmJ4Meta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailShAT883FSKMeta?.name ?? "auth-verify-email___hu",
    path: verify_45emailShAT883FSKMeta?.path ?? "/hu/auth/verify-email",
    meta: verify_45emailShAT883FSKMeta || {},
    alias: verify_45emailShAT883FSKMeta?.alias || [],
    redirect: verify_45emailShAT883FSKMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    path: checkoutU7ydjr5EV5Meta?.path ?? "/hu/checkout",
    children: [
  {
    name: _91code_93n8bQXXLUreMeta?.name ?? "checkout-confirmation-code___hu",
    path: _91code_93n8bQXXLUreMeta?.path ?? "confirmation/:code()",
    meta: _91code_93n8bQXXLUreMeta || {},
    alias: _91code_93n8bQXXLUreMeta?.alias || [],
    redirect: _91code_93n8bQXXLUreMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: indexS4P2YxqBHcMeta?.name ?? "checkout___hu",
    path: indexS4P2YxqBHcMeta?.path ?? "",
    meta: indexS4P2YxqBHcMeta || {},
    alias: indexS4P2YxqBHcMeta?.alias || [],
    redirect: indexS4P2YxqBHcMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/checkout/index.vue").then(m => m.default || m)
  }
],
    name: checkoutU7ydjr5EV5Meta?.name ?? undefined,
    meta: checkoutU7ydjr5EV5Meta || {},
    alias: checkoutU7ydjr5EV5Meta?.alias || [],
    redirect: checkoutU7ydjr5EV5Meta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: _91slug_939CT60eHGEsMeta?.name ?? "collections-id-slug___hu",
    path: _91slug_939CT60eHGEsMeta?.path ?? "/hu/collections/:id()/:slug()",
    meta: _91slug_939CT60eHGEsMeta || {},
    alias: _91slug_939CT60eHGEsMeta?.alias || [],
    redirect: _91slug_939CT60eHGEsMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/collections/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: commitmentsPspvuWFo6MMeta?.name ?? "commitments___hu",
    path: commitmentsPspvuWFo6MMeta?.path ?? "/hu/commitments",
    meta: commitmentsPspvuWFo6MMeta || {},
    alias: commitmentsPspvuWFo6MMeta?.alias || [],
    redirect: commitmentsPspvuWFo6MMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/commitments.vue").then(m => m.default || m)
  },
  {
    name: contactU3U0nxWLZbMeta?.name ?? "contact___hu",
    path: contactU3U0nxWLZbMeta?.path ?? "/hu/contact",
    meta: contactU3U0nxWLZbMeta || {},
    alias: contactU3U0nxWLZbMeta?.alias || [],
    redirect: contactU3U0nxWLZbMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexZRmv4rwJvqMeta?.name ?? "index___hu",
    path: indexZRmv4rwJvqMeta?.path ?? "/hu",
    meta: indexZRmv4rwJvqMeta || {},
    alias: indexZRmv4rwJvqMeta?.alias || [],
    redirect: indexZRmv4rwJvqMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qd7SH03cQCMeta?.name ?? "pages-slug___hu",
    path: _91slug_93Qd7SH03cQCMeta?.path ?? "/hu/pages/:slug()",
    meta: _91slug_93Qd7SH03cQCMeta || {},
    alias: _91slug_93Qd7SH03cQCMeta?.alias || [],
    redirect: _91slug_93Qd7SH03cQCMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_939lFgEk0npoMeta?.name ?? "products-id-slug___hu",
    path: _91_91slug_93_939lFgEk0npoMeta?.path ?? "/hu/products/:id()/:slug?",
    meta: _91_91slug_93_939lFgEk0npoMeta || {},
    alias: _91_91slug_93_939lFgEk0npoMeta?.alias || [],
    redirect: _91_91slug_93_939lFgEk0npoMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: searchYhxkQS4hULMeta?.name ?? "search___hu",
    path: searchYhxkQS4hULMeta?.path ?? "/hu/search",
    meta: searchYhxkQS4hULMeta || {},
    alias: searchYhxkQS4hULMeta?.alias || [],
    redirect: searchYhxkQS4hULMeta?.redirect || undefined,
    component: () => import("/usr/local/app/pages/search.vue").then(m => m.default || m)
  }
]