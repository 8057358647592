import type { $Img, CreateImageOptions, ImageContext, ImageDimensionsOptions, ImageSizesOptions } from '../types'
import { getDimensions, getSizes, resolveImage } from './common'

export function createImageHandler(globalOptions: CreateImageOptions) {
  const ctx: ImageContext = { options: globalOptions }

  // get image for default
  const getImage: $Img['getImage'] = (input, options) => resolveImage(ctx, input, options)

  // set default return type to string
  const $img = ((input, edits, options) => getImage(input, {
    ...options,
    edits: {
      ...edits,
      ...options?.edits,
      resize: {
        ...edits?.resize,
        ...options?.edits?.resize,
      },
    },
  }).url) as $Img

  for (const presetName in globalOptions.presets) {
    $img[presetName] = ((input, edits, options) =>
      $img(input, edits, {
        ...globalOptions.presets[presetName],
        ...options,
      })
    ) as $Img[string]
  }

  $img.options = globalOptions
  $img.getImage = getImage
  $img.getSizes = ((input: string, options: ImageSizesOptions) => getSizes(ctx, input, options)) as $Img['getSizes']
  $img.getDimensions = ((input: string, options: ImageDimensionsOptions) => getDimensions(ctx, input, options)) as $Img['getDimensions']

  ctx.$img = $img as $Img

  return $img
}
