import type { AddressFragment, CreateAddressInput, CustomerFragment, RequestUpdateCustomerEmailAddressMutationVariables, UpdateAddressInput, UpdateCustomerEmailAddressMutationVariables, UpdateCustomerInput, UpdateCustomerPasswordMutationVariables } from '#graphql-operations'

export const fetchActiveCustomer = () => {
  const state = useStore()

  return reactiveLoad<CustomerFragment | undefined | null>(
    () => state.value.activeCustomer,
    (activeCustomer) => {
      if (state.value.activeCustomer) {
        Object.assign(state.value.activeCustomer, activeCustomer)
      } else {
        state.value.activeCustomer = activeCustomer
      }
    },
    () => useGraphqlQuery('activeCustomer').then(result => result.data.activeCustomer),
  )
}

export const fetchActiveCustomerAddresses = () => {
  const state = useStore()

  return reactiveLoad<AddressFragment[] | undefined | null>(
    () => state.value.addresses,
    (addresses) => {
      state.value.addresses = addresses
    },
    () => useGraphqlQuery('activeCustomerAddresses').then(result => result.data.activeCustomer?.addresses),
  )
}

export const useActiveCustomer = () => {
  const state = useStore()

  const updateCustomer = async (body: UpdateCustomerInput) => {
    const customer = await useGraphqlMutation('updateCustomer', { input: body }).then(result => result.data)

    if (state.value.activeCustomer) {
      Object.assign(state.value.activeCustomer, customer.updateCustomer)
    } else {
      state.value.activeCustomer = customer.updateCustomer
    }
  }

  const requestUpdateCustomerEmailAddress = async (body: RequestUpdateCustomerEmailAddressMutationVariables) => {
    await useGraphqlMutation('requestUpdateCustomerEmailAddress', body)
  }

  const updateCustomerEmailAddress = async (body: UpdateCustomerEmailAddressMutationVariables) => {
    await useGraphqlMutation('updateCustomerEmailAddress', body)
    state.value.activeCustomer = undefined
  }

  const updateCustomerPassword = async (body: UpdateCustomerPasswordMutationVariables) => {
    await useGraphqlMutation('updateCustomerPassword', body)
  }

  const createCustomerAddress = async (body: CreateAddressInput) => {
    const { data } = await useGraphqlMutation('createCustomerAddress', { input: body })
    state.value.addresses ??= []
    state.value.addresses.push(data.createCustomerAddress)
  }

  const deleteCustomerAddress = async (id: string) => {
    await useGraphqlMutation('deleteCustomerAddress', { id })
    state.value.addresses = state.value.addresses?.filter(address => address.id !== id)
  }

  const updateCustomerAddress = async (body: UpdateAddressInput) => {
    const { data } = await useGraphqlMutation('updateCustomerAddress', { input: body })
    const index = state.value.addresses?.findIndex(address => address.id === data.updateCustomerAddress.id)
    if (index !== undefined) {
      state.value.addresses?.splice(index, 1, data.updateCustomerAddress)
    }
  }

  return {
    activeCustomer: computed(() => state.value.activeCustomer),
    addresses: computed(() => state.value.addresses),
    updateCustomer,
    requestUpdateCustomerEmailAddress,
    updateCustomerEmailAddress,
    updateCustomerPassword,
    createCustomerAddress,
    deleteCustomerAddress,
    updateCustomerAddress,
  }
}

export const getCustomerFullName = (
  customer: Omit<CustomerFragment, 'id'> | undefined | null,
  address: (Omit<AddressFragment, 'id' | 'country' | 'streetLine1'> & {
    streetLine1?: string | null
    countryCode?: string | null
  }) | undefined | null,
) => address?.fullName ? address.fullName : `${customer?.firstName || ''} ${customer?.lastName || ''}`.trim()

export const getCustomerPhoneNumber = (
  customer: Omit<CustomerFragment, 'id'> | undefined | null,
  address: (Omit<AddressFragment, 'id' | 'country' | 'streetLine1'> & {
    streetLine1?: string | null
    countryCode?: string | null
  }) | undefined | null,
) => address?.phoneNumber || customer?.phoneNumber || ''
