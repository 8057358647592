import type { HttpImage, S3Image } from '../../types'
export * from './base64url'
export { getColor } from './color'

const numberRE = /[\d.]+/g

export const normalizeSize = (size?: string | number) => {
  if (typeof size === 'number') {
    return size
  }

  if (typeof size === 'string') {
    const value = Number((numberRE.exec(size) ?? [])[0])
    numberRE.lastIndex = 0

    if (value) {
      return (value >= 0 && value < 0x80000000) ? value & 0xFFFFFFFF : 0x80000000
    }
  }

  return undefined
}

export const clamp = (value: number, min: number, max: number) => value <= min ? min : value >= max ? max : value

export const isObject = (value: unknown): value is Record<string, unknown> => value !== null && typeof value === 'object'

export const isS3Image = (value: unknown): value is S3Image => isObject(value) && Object.hasOwn(value, 'bucket') && Object.hasOwn(value, 'key')
export const isHttpImage = (value: unknown): value is HttpImage => isObject(value) && (Object.hasOwn(value, 'url') || (Object.hasOwn(value, 'path') && Object.hasOwn(value, 'host')))
