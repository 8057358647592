const paddingRE = /=/g
const unsafeCharactersRE = /[+/]/g

const base64UrlRE = /[^A-Za-z0-9+/]/g
const safeCharactersRE = /[-_]/g

const sanitize = (value: string) => value.replace(base64UrlRE, '')

export const stringToBase64Url = (value: string) => {
  const base64 = process.server
    ? Buffer.from(value, 'binary').toString('base64')
    : window.btoa(value)

  return base64.replace(paddingRE, '').replace(unsafeCharactersRE, m => (m === '+' ? '-' : '_'))
}

export const base64UrlToBase64 = (base64: string) => sanitize(base64.replace(safeCharactersRE, m => (m === '-' ? '+' : '/')))

export const base64ToString = (base64: string) => {
  base64 = base64UrlToBase64(base64)

  return process.server
    ? Buffer.from(base64, 'base64').toString('binary')
    : window.atob(base64)
}
